import React from "react"
import { Link } from "gatsby"
import Header from "../layouts/header4"
import Footer from "../layouts/footer4"
import FloatingBtn from "../components/custom-components/floatingBtn"

import Layout from "../components/custom-components/Layout"

import "../css/custom-css/custom-contact-us.css"

import bg from "../images/contact-us/banner-contact-us.png"
import Seo from "../components/seo"

const ContactUs = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="Contact Us" />
      <Header ContactUs />
      <FloatingBtn />

      {/* Header */}
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr header-contact overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")", objectFit:"cover" }}
        >
          <div className="hero-content">
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <div className="d-flex flex-column text-left entry-contact">
                  <h1 className="text-white">Contact Us</h1>
                </div>
                <div className="breadcrumb-row entry-contact">
                  <ul className="list-inline entry-contact">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div className="section-full content-inner bg-white contact-style-1">
        <div className="container">
          
          <div className="row">
            <div className="col-lg-4 d-flex m-b30">
              <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                <h3 className="m--b5">Indonesia Office</h3>
                
                <ul className="no-margin">
                  <li className="icon-bx-wraper left m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-location-pin"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        Address
                      </h6>
                      <p>
                        KALBE BUSINESS INNOVATION CENTER Jl. Pulogadung No.23,
                        3rd Floor, Jatinegara, Cakung, Jakarta 13930 Indonesia
                        
                      </p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left  m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-email"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">Email</h6>
                      <p>info@kalgendna.co.id</p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-mobile"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">PHONE</h6>
                      <p>+62 21 80604202</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-8 d-flex m-b30">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5297035501876!2d106.91579731529487!3d-6.193617662405051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698b4b3f6c773f%3A0xc53ea2b6fa6f0daa!2sKalbe%20Business%20Innovation%20Center!5e0!3m2!1sen!2sid!4v1668667037228!5m2!1sen!2sid"
                style={{ border: "0", width: "100%", minHeight: "100%" }}
                allowfullscreen
              ></iframe>
            </div>

            

            <div className="col-lg-12 mb-4 m-b30 mb-md-0 mt-5">
              <div className="p-a30 bg-gray clearfix radius-sm">
                <h3 className="m-b10">Send Us Message</h3>
                <div className="dzFormMsg"></div>
                <form
                  className="dzForm"
                  acceptCharset="utf-8"
                  action="https://formspree.io/f/xvoyyyqv"
                  method="POST"
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="name"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            required
                            placeholder="E-mail"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <textarea
                            name="message"
                            rows="4"
                            className="form-control"
                            required
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="site-button btnhover11">
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer hideContactInfo />
    </div>
  )
}

export default ContactUs


